<template>
  <a-table
    size="middle"
    :columns="columns"
    :data-source="data"
    :loading="loading"
    :pagination="false"
    row-key="id"
  >

    <span slot="product_price_title">
      <a-tooltip>
        <template slot="title">
          这里显示的是套餐周期单价，精确到两位小数
        </template>
        套餐价格(元)
        <a-icon type="question-circle" />
      </a-tooltip>
    </span>
  </a-table>
</template>

<script>

import { formatCurrency } from '@/utils/filter'

export default {
  name: 'BatchOperationRecordRenewDetailsList',
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          title: 'ICCID/SIMID',
          width: 200,
          dataIndex: 'iccid'
        },
        {
          title: '客户套餐',
          width: 200,
          dataIndex: 'extra_data.product_name'
        },
        {
          title: '用户业务模式',
          width: 100,
          dataIndex: 'extra_data.user_business_type'
        },
        {
          slots: { title: 'product_price_title' },
          width: 120,
          customRender: this.productPriceRender,
          dataIndex: 'extra_data.product_price'
        },
        {
          title: '续期金额(元)',
          width: 100,
          customRender: this.totalFeeRender,
          dataIndex: 'extra_data.total_fee'
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status'
        },
        {
          title: '操作失败原因',
          width: 200,
          ellipsis: true,
          dataIndex: 'failure_reason'
        },
        {
          title: '重跑次数',
          width: 100,
          dataIndex: 'run_times'
        }
      ]
    }
  },
  methods: {
    productPriceRender(val) {
      if (val === -1) {
        return ''
      } else {
        return formatCurrency(val)
      }
    },

    totalFeeRender(val) {
      if (val === -1) {
        return ''
      } else {
        return formatCurrency(val)
      }
    }
  }
}
</script>

